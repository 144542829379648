<template>
    <div>
      
  
     
  
      <!-- Dashboard -->
      <section @submit.prevent="add" class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Update City</h3>
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box p-3">
                  <div class="widget-content">
                    <form class="default-form">
                      <div class="row">
                        <!-- Input -->
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>City Name</label>
                          <input
                            type="text"
                            v-model="city.name"
                            placeholder="Category Name"
                            required
                          />
                        </div>

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Code</label>
                          <input
                            type="text"
                            v-model="city.code"
                            placeholder="Category Name"
                            required
                          />
                        </div>
  
                   
                       
  
                   
                       
                 
  
                      
                     
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Status</label>
                          <select  v-model="city.status">
                            <option  value="null">Select a option</option>
  
                            <option value="1">Enable</option>
                            <option value="0">Disable</option>
                          </select>
                        </div>
  
                      
                       
                        <!-- Input -->
                        <div class="form-group col-lg-12 col-md-12 text-right">
                          <button @click.prevent="update"  type="submit" class="theme-btn btn-style-one">
                            Update City
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
    
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  
  export default {
  
    data() {
      return {
        categories: [],
        editor: ClassicEditor,
        city_id: null,

        status: null,

        city:{},

        icon : "",

        id : "",
       
      };
    },
  
    methods: {

      getCity($id){
    axios.get("api/admin/city/"+$id).then((res) => {

      this.city = res.data.data;
      
    }).catch((error) => {
      console.log(error.response);
    });

   },

  
   update(){
     var formData = new FormData();
     formData.append("name", this.city.name);
     formData.append("code", this.city.code);
     formData.append("status", this.city.status);
     formData.append("icon", this.icon);
     formData.append("_method", "PUT");

     axios.post("api/admin/city/"+this.id, formData).then((res)=>{
       
      this.$emit("get_message", res.data.message);
       
     }).catch((error)=>{
       console.log(error.response)
     })
   },

   iconSelect(event) {
      this.icon = event.target.files[0]; 
      },
   
 
  
    
     
    },
    mounted: function () {
         var $id = this.$route.query.id
         this.id = $id
       this.getCity($id)
    },
  };
  </script>
  
  
  
  <style>
  .button_plus {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    cursor: pointer;
    border: 2px solid #095776;
    display: flex;
  
    /* Mittig */
    top: 44%;
    left: 90%;
  }
  
  .button_plus:after {
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    height: 4px;
    width: 50%;
    background: #095776;
    top: 50%;
    left: 50%;
  }
  
  .button_plus:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #095776;
    height: 50%;
    width: 4px;
  }
  
  .button_plus:hover:before,
  .button_plus:hover:after {
    background: #fff;
    transition: 0.2s;
  }
  
  .button_plus:hover {
    background-color: #095776;
    transition: 0.2s;
  }
  </style>