import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/pages/HomeView'
import LoginView from '@/views/pages/LoginView'
import UniversityListView from '@/views/pages/UniversityListView'
import UniversityProgramsView from '@/views/pages/UniversityProgramsView'
import ProgramsView from '@/views/pages/ProgramsView'
import CandidatesListView from '@/views/pages/CandidatesListView'
import ContactView from '@/views/pages/ContactView'
import NeedHelpView from '@/views/pages/NeedHelpView'
import HowItWorks from '@/views/pages/HowItWorks'

//Auth
import AdminLoginView from '@/views/pages/AdminLoginView'



import RegisterView from '@/views/pages/RegisterView'
import CandidateView from '@/views/pages/CandidateView'
import TermsView from '@/views/pages/TermsView'
import PrivacyView from '@/views/pages/PrivacyView'

import PostAJobView from '@/views/pages/PostAJobView'
import DashboardAdminView from '@/views/dashboard/DashboardAdminView'
import DashboardCandidateView from '@/views/dashboard/DashboardCandidateView'

// Admin View
import AdminDashboardView from '@/views/dashboard/admin/AdminDashboardView'
import AdminUniversitylistView from '../views/dashboard/admin/AdminUniversitylistView'
import AdminAddUniversity from '@/views/dashboard/admin/AdminAddUniversity'
import AdminAddCategory from '@/views/dashboard/admin/AdminAddCategory'
import AdminAddProgram from '@/views/dashboard/admin/AdminAddProgram'
import AdminAddCourse from '@/views/dashboard/admin/AdminAddCourse'
import AdminCategoryList from '@/views/dashboard/admin/AdminCategoryList'
import AdminProgramList from '@/views/dashboard/admin/AdminProgramList'
import AdminCourseList from '@/views/dashboard/admin/AdminCourseList'
import AdminUpdateCategory from '@/views/dashboard/admin/AdminUpdateCategory'
import AdminUpdateProgram from '@/views/dashboard/admin/AdminUpdateProgram'
import AdminUpdateCourse from '@/views/dashboard/admin/AdminUpdateCourse'
import AdminUpdateUniversity from '@/views/dashboard/admin/AdminUpdateUniversity'
import AdminAddCity from '@/views/dashboard/admin/AdminAddCity'
import AdminAddCountry from '@/views/dashboard/admin/AdminAddCountry'
import AdminCityList from '@/views/dashboard/admin/AdminCityList'
import AdminUpdateCity from '@/views/dashboard/admin/AdminUpdateCity'
import AdminCountryList from '@/views/dashboard/admin/AdminCountryList'

//Student Dashboard
import StudentDashboardView from '@/views/dashboard/student/StudentDashboardView'
import ApplicationListView from '@/views/dashboard/student/ApplicationListView'
import StudentUpdateProfileView from '@/views/dashboard/student/StudentUpdateProfileView'




import DashboardAdminAllEmpView from '@/views/dashboard/DashboardAdminAllEmpView'
import DashboardAdminAllApplicantView from '@/views/dashboard/DashboardAdminAllApplicantView'
import AdminAddJobCategoryView from '../views/dashboard/AdminAddJobCategoryView'
import AdminEmployerTypeView from '@/views/dashboard/AdminEmployerTypeView'
import AdminContractApplicantView from '@/views/dashboard/AdminContractApplicantView'
import AdminContractHireView from '@/views/dashboard/AdminContractHireView'

import AdminPaymentStatusView from '@/views/dashboard/AdminPaymentStatusView'
import AdminDeleteProfileView from '@/views/dashboard/AdminDeleteProfileView'
import AdminChangePasswordView from '@/views/dashboard/AdminChangePasswordView'
import AdminDetailApplicantView from '@/views/dashboard/AdminDetailApplicantView'
import AdminDetailEmpView from '@/views/dashboard/AdminDetailEmpView'
import EmployerProfileView from '@/views/dashboard/EmployerProfileView'
import CreateProgram from '@/views/dashboard/CreateProgram'
import CreateOfferView from '@/views/dashboard/CreateOfferView'
import UniversityCategoryView from '../views/dashboard/UniversityCategoryView'
import UniversityAddCategoryView from '../views/dashboard/UniversityAddCategoryView'
import EmployerAllApplicantsView from '../views/dashboard/EmployerAllApplicantsView'
import EmployerContractApplicantsView from '../views/dashboard/EmployerContractApplicantsView'
import EmployerChangePasswordView from '../views/dashboard/EmployerChangePasswordView'
import EmployerDeleteProfileView from '../views/dashboard/EmployerDeleteProfileView'
import CandidateProfileView from '../views/dashboard/CandidateProfileView'
import CandidateResumeView from '../views/dashboard/CandidateResumeView'
import CandidateCvManagerView from '../views/dashboard/CandidateCvManagerView'
import DirectHireView from '../views/pages/DirectHireView'
import SearchView from '../views/pages/SearchView'
import JobCategoryView from '../views/pages/JobCategoryView'

import CandidateFindJobsView from '../views/dashboard/CandidateFindJobsView'

import ChangePasswordView from '../views/dashboard/ChangePasswordView'


import CandidateAppliedJobsView from '../views/dashboard/CandidateAppliedJobsView'
import CandidateChangePasswordView from '../views/dashboard/CandidateChangePasswordView'
import ShopingCartView from '../views/pages/ShopingCartView'
import CheckoutView from '../views/pages/CheckoutView'
import AdminManageJobView from '../views/dashboard/AdminManageJobView'
import UniversityView from '../views/pages/UniversityView'

import vcookie from 'vue-cookie'



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/login',
    name: 'login',
    meta: { layout: "auth" },
    component: LoginView
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    meta: { layout: "auth" },
    component: AdminLoginView
  },


  {
    path: '/register',
    name: 'register',
    meta: { layout: "auth" },
    component: RegisterView
  },
  {
    path: '/universitylist',
    name: 'universitylist',
    component: UniversityListView
  },
  {
    path: '/university-programs',
    name: 'university-programs',
    component: UniversityProgramsView
  },
  {
    path: '/postajob',
    name: 'postajob',
    component: PostAJobView
  },
  {
    path: '/candidates',
    name: 'candidates',
    component: CandidatesListView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },

  {
    path: '/needhelp',
    name: 'needhelp',
    component: NeedHelpView
  },

  {
    path: '/howitworks',
    name: 'howitworks',
    component: HowItWorks
  },
  {
    path: '/programs',
    name: 'programs',
    component: ProgramsView
  },



  {
    path: '/candidate',
    name: 'candidate',
    component: CandidateView
  },

  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },

  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },

  {
    path: '/search',
    name: 'search',
    component: SearchView
  },
 


  {
    path: '/job-category',
    name: 'job-category',
    component: JobCategoryView
  },
  {
    path: '/university',
    name: 'university',
    component: UniversityView
  },
 


  //  dashboard Admin section start
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: DashboardAdminView
  },

  {
    path: '/change-password',
    name: 'change-password',
    meta: { layout: "admin", requiresAuth: true },
    component: ChangePasswordView
  },
  {
    path: '/admin-dashboard-allemployer',
    name: 'admin-dashboard-allemployer',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: DashboardAdminAllEmpView
  },
  {
    path: '/admin-dashboard-allapplicant',
    name: 'admin-dashboard-allapplicant',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: DashboardAdminAllApplicantView
  },
  {
    path: '/admin-add-job-category',
    name: 'admin-add-job-category',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminAddJobCategoryView
  },
  {
    path: '/admin-add-employer-type',
    name: 'admin-add-employer-type',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminEmployerTypeView
  },
  {
    path: '/admin-payment-status',
    name: 'admin-payment-status',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminPaymentStatusView
  },
  {
    path: '/admin-add-contract-type',
    name: 'admin-add-contract-type',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminContractApplicantView
  },

  {
    path: '/admin-add-contracthire-type',
    name: 'admin-add-contracthire-type',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminContractHireView
  },



  {
    path: '/admin-delete-profile',
    name: 'admin-delete-profile',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminDeleteProfileView
  },
  {
    path: '/admin-change-password',
    name: 'admin-change-password',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminChangePasswordView
  },

  {
    path: '/admin-applicant-detail',
    name: 'admin-applicant-detail',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminDetailApplicantView
  },

  {
    path: '/admin-employer-detail',
    name: 'admin-employer-detail',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminDetailEmpView
  },


  //  dashboard Admin section end

 

  {
    path: '/admin',
    name: 'admin',
    meta: { layout: "admin", requiresAdminAuth: true },
    children: [
       {
        path: 'dashboard',
        name: 'admin-dashboard',
        component: AdminDashboardView,
       },

        {
           path: 'universitylist',
           name: 'admin-universitylist',
           component: AdminUniversitylistView,
        },

        {
          path: 'add-university',
          name: 'admin-add-university',
          component: AdminAddUniversity,
       },
        {
          path: 'add-category',
          name: 'admin-add-category',
          component: AdminAddCategory,
       },
        {
          path: 'add-program',
          name: 'admin-add-program',
          component: AdminAddProgram,
       },
        {
          path: 'add-course',
          name: 'admin-add-course',
          component: AdminAddCourse,
       },
        {
          path: 'categorylist',
          name: 'categorylist',
          component: AdminCategoryList,
       },
        {
          path: 'programlist',
          name: 'programlist',
          component: AdminProgramList,
       },
        {
          path: 'citylist',
          name: 'citylist',
          component: AdminCityList,
       },
       {
        path: 'courselist',
        name: 'courselist',
        component: AdminCourseList,
       },

       {
        path: 'update-category',
        name: 'update-category',
        component: AdminUpdateCategory,
       },
       {
        path: 'update-program',
        name: 'update-program',
        component: AdminUpdateProgram,
       },
       {
        path: 'update-course',
        name: 'update-course',
        component: AdminUpdateCourse,
       },
       {
        path: 'update-university',
        name: 'update-university',
        component: AdminUpdateUniversity,
       },
       {
        path: 'add-city',
        name: 'add-city',
        component: AdminAddCity,
       },
       {
        path: 'add-country',
        name: 'add-country',
        component: AdminAddCountry,
       },
       {
        path: 'update-city',
        name: 'update-city',
        component: AdminUpdateCity,
       },
       {
        path: 'country-list',
        name: 'country-list',
        component: AdminCountryList,
       },

      ]


    },


    {
      path: '/student',
      name: 'student',
      meta: { layout: "student", requireStudentAuth: true },
      children: [
         {
          path: 'dashboard',
          name: 'student-dashboard',
          component: StudentDashboardView,
         },

         {
          path: 'applied-program',
          name: 'applied-program',
          component: ApplicationListView,
         },
         {
          path: 'update-profile',
          name: 'student-update-profile',
          component: StudentUpdateProfileView,
         },



         



       
        ]
  
  
      },
     





  //  dashboard employer section start
  
  {
    path: '/employer-profile',
    name: 'employer-profile',
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerProfileView
  },


  {
    path: '/create-program',
    name: 'create-program',
    meta: { layout: "admin", requiresRecAuth: true },
    component: CreateProgram
  },
  {
    path: '/create-offer',
    name: 'create-offer',
    meta: { layout: "admin", requiresRecAuth: true },
    component: CreateOfferView
  },

 
  {
    path: '/employer-manage-category',
    name: 'employer-manage-category',
    meta: { layout: "admin", requiresRecAuth: true },
    component: UniversityCategoryView
  },
  {
    path: '/employer-add-category',
    name: 'employer-add-category',
    meta: { layout: "admin", requiresRecAuth: true },
    component: UniversityAddCategoryView
  },

  {
    path: '/employer-allapplicants',
    name: 'employer-allapplicants',
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerAllApplicantsView
  },

  {
    path: '/admin-managejob',
    name: 'admin-managejob',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AdminManageJobView
  },

  {
    path: '/employer-contractapplicants',
    name: 'employer-contractapplicants',
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerContractApplicantsView
  },

  {
    path: '/employer-changepassword',
    name: 'employer-changepassword',
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerChangePasswordView
  },
  {
    path: '/employer-deleteprofile',
    name: 'employer-deleteprofile',
    meta: { layout: "admin", requiresRecAuth: true },
    component: EmployerDeleteProfileView
  },
  {
    path: '/shopingcart',
    name: 'shopingcart',
    //meta:{layout:"admin"},
    component: ShopingCartView
  },

  {
    path: '/checkout',
    name: 'checkout',
    //meta:{layout:"admin"},
    component: CheckoutView
  },

  //  dashboard employer section end


  //  dashboard Applicant section start
  {
    path: '/candidate-dashboard',
    name: 'candidate-dashboard',
    meta: { layout: "admin" },

    component: DashboardCandidateView
  },

  {
    path: '/candidate-profile',
    name: 'candidate-profile',
    meta: { layout: "admin" },
    component: CandidateProfileView
  },

  {
    path: '/candidate-resume',
    name: 'candidate-resume',
    meta: { layout: "admin" },
    component: CandidateResumeView
  },

  {
    path: '/candidate-CvManager',
    name: 'candidate-CvManager',
    meta: { layout: "admin" },
    component: CandidateCvManagerView
  },

  {
    path: '/candidate-findjob',
    name: 'candidate-findjob',
    meta: { layout: "admin" },
    component: CandidateFindJobsView
  },

  {
    path: '/directhire',
    name: 'directhire',
    component: DirectHireView
  },

  {
    path: '/candidate-appliedjob',
    name: 'candidate-appliedjob',
    meta: { layout: "admin" },
    component: CandidateAppliedJobsView
  },

  {
    path: '/candidate-change-password',
    name: 'candidate-change-password',
    meta: { layout: "admin" },
    component: CandidateChangePasswordView
  },

  //  dashboard Applicant section end

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAdminAuth)) {

    const user = JSON.parse(vcookie.get('user'));

    if (user) {


      if (user.role == "admin") {

        next();

      } else {
       next({ name: 'admin-login' });


      }

    }
    else {

      next({ name: 'admin-login' });



    }
  }


  else if (to.matched.some(record => record.meta.requireStudentAuth)) {

    const user = JSON.parse(vcookie.get('user'));

    if (user) {


      if (user.role == "student") {

        next();

      } else {
       next({ name: 'login' });


      }

    }
    else {

      next({ name: 'login' });



    }
  }

  else if (to.matched.some(record => record.meta.requiresCanAuth)) {

    const user = JSON.parse(vcookie.get('user'));

    if (user) {


      if (user.user.role == "J") {

        next();

      } else {
        next();

      }

    }
    else {

      next();


    }
  }
  else if (to.matched.some(record => record.meta.requiresAuth)) {

    const user = JSON.parse(vcookie.get('user'));

    if (user) {



      next();


    }
    else {

      next();



    }
  }

  else {
    next()



  }




});

export default router
