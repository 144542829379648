<template>
    <div class="user-sidebar">
       <div class="sidebar-inner">
         <ul class="navigation">
           <li >
             <router-link :to="{name:'student-dashboard'}"
               ><i class="la la-paper-plane"></i>Student Dashboard</router-link>
           </li>
        
           <li >
             <router-link :to="{name:'applied-program'}"
               ><i class="la la-paper-plane"></i>Applied University</router-link>
           </li>

           <li >
             <router-link :to="{name:'student-update-profile'}"
               ><i class="la la-paper-plane"></i>Profile</router-link>
           </li>
         
         
        
           <!-- <li>
             <a href="/employer-allapplicants"
               ><i class="la la-file-invoice"></i> All Applicants</a
             >
           </li> -->
       
           <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          
           <li>
             <a href="#"><i class="la la-lock"></i>Change Password</a>
           </li>
 
           <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
           <li>
             <router-link :to="{name:'login'}"
               ><i class="la la-paper-plane"></i>Logout</router-link>
           </li>
         </ul>
       </div>
     </div>
 </template>
 
 <script>
 export default {
 
 }
 </script>
 
 <style>
 
 </style>