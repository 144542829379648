<template>
    <div>
      
  
     
  
      <!-- Dashboard -->
      <section @submit.prevent="add" class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Update your Profile</h3>
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box p-3">
                  <div class="widget-content">
                    <form class="default-form">

                        <h5>Basic Information</h5>
                      <div class="row mt-4">
        
                    <!-- Input -->
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>First  name</label>
                          <input
                            type="text"
                            v-model="basic_info.first_name"
                            placeholder="First  name"
                            required
                          />
                        </div>
  
                   
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Last  name</label>
                          <input
                            type="text"
                            v-model="basic_info.last_name"
                            placeholder="Last  name"
                            required
                          />
                        </div>

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Parent Name</label>
                          <input
                            type="text"
                            v-model="basic_info.parent_name"
                            placeholder="Parent Name"
                            required
                          />
                        </div>
  
  
                   
                       
                 
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label> Student Type</label>
                          <select  v-model="basic_info.type">
                            <option  value="null">Select a type</option>
                            <option value="Bachelor">Local</option>
                            <option value="Masters">Foiegn</option>
                          </select>
                        </div>

                        
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Nationality</label>
                          <input
                            type="text"
                            v-model="basic_info.nationality"
                            placeholder="Nationality"
                            required
                          />
                        </div>




                        <div class="form-group col-lg-4 col-md-12">
                          <label> Gender</label>
                          <select  v-model="basic_info.gender">
                            <option  value="null">Select a gender</option>
                            <option value="Bachelor">Male</option>
                            <option value="Masters">Female</option>
                          </select>
                        </div>

  
                      
                     
  

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Date of Birth</label>
                          <input
                            class="form-control"
                            type="date"
                            v-model="basic_info.dob"
                            placeholder="Date of Birth"
                            
                          />
                        </div>
  
                        <div class="form-group col-lg-6 col-md-12">
                          <label>National Indenty</label>
                          <input class="form-control"
                            type="file"
                          
                            placeholder="Total program"
                          />
                        </div>


                        <div class="form-group col-lg-12 col-md-12 text-right">
                          <button  @click.prevent="add_basic_info" class="theme-btn btn-style-one">
                            Save
                          </button>
                        </div>
                      
                       
                        <!-- Input -->
                       
                      </div>
                        <h5>Education Information (School )</h5>
                      <div class="row mt-4">

                        <!-- Input -->
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>School Name</label>
                          <input
                            type="text"
                            v-model="education_school.school_name"
                            placeholder="School Name"
                            required
                          />
                        </div>
  
                   
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Examination</label>
                          <input
                            type="text"
                            v-model="education_school.examination_name"
                            placeholder="Examination"
                            required
                          />
                        </div>

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Year</label>
                          <input
                            type="text"
                            v-model="education_school.year"
                            placeholder="Year"
                            required
                          />
                        </div>
  
  
                   
                       
                 
  
                      
                     
  
                      

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Duration</label>
                          <input
                            type="text"
                            v-model="education_school.duration"
                            placeholder="Duration"
                            
                          />
                        </div>
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Grade</label>
                          <input
                            type="text"
                            v-model="education_school.grade"
                            placeholder="Grade"
                            
                          />
                        </div>
  
                        <div class="form-group col-lg-6 col-md-12">
                          <label>Certificate</label>
                          <input class="form-control"
                            type="file"
                          
                            placeholder="Total program"
                          />
                        </div>
                       
                        <div class="form-group col-lg-12 col-md-12 text-right">
                          <button @click.prevent="add_eduction_school" class="theme-btn btn-style-one">
                            Save
                          </button>
                        </div>
                      
                    
                    
                    
                    </div>
                    
                        <h5>Education Information (Higer school )</h5>
                      <div class="row mt-4">

                        <!-- Input -->
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>School Name</label>
                          <input
                            type="text"
                            v-model="education_higher.higher_school_name"
                            placeholder="School Name"
                            required
                          />
                        </div>
  
                   
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Examination</label>
                          <input
                            type="text"
                            v-model="education_higher.higher_examination_name"
                            placeholder="Examination"
                            required
                          />
                        </div>

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Year</label>
                          <input
                            type="text"
                            v-model="education_higher.higher_year"
                            placeholder="Year"
                            required
                          />
                        </div>
  
  
                   
                       
                 
  
                      
                     
  
                      

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Duration</label>
                          <input
                            type="text"
                            v-model="education_higher.higher_duration"
                            placeholder="Duration"
                            
                          />
                        </div>
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Grade</label>
                          <input
                            type="text"
                            v-model="education_higher.higher_grade"
                            placeholder="Grade"
                            
                          />
                        </div>
  
                        <div class="form-group col-lg-6 col-md-12">
                          <label>Certificate</label>
                          <input class="form-control"
                            type="file"
                          
                            placeholder="Total program"
                          />
                        </div>
                       
                        <!-- Input -->
                        <div class="form-group col-lg-12 col-md-12 text-right">
                          <button @click.prevent="education_information_higher_school" class="theme-btn btn-style-one">
                            Save
                          </button>
                        </div>
                      
                    
                    
                    </div>


                    <h5>Education Information (Bachelor)</h5>
                      <div class="row mt-4">

                        <!-- Input -->
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Instute Name</label>
                          <input
                            type="text"
                            v-model="education_bachelor.bachelor_instute_name"
                            placeholder="School Name"
                            required
                          />
                        </div>
  
                   
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Examination</label>
                          <input
                            type="text"
                            v-model="education_bachelor.bachelor_eximation_name"
                            placeholder="Examination"
                            required
                          />
                        </div>

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Year</label>
                          <input
                            type="text"
                            v-model="education_bachelor.bachelor_year"
                            placeholder="Year"
                            required
                          />
                        </div>
  
  
                   
                       
                 
  
                      
                     
  
                      

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Duration</label>
                          <input
                            type="text"
                            v-model="education_bachelor.bachelor_duration"
                            placeholder="Duration"
                            
                          />
                        </div>
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Grade</label>
                          <input
                            type="text"
                            v-model="education_bachelor.bachelor_grade"
                            placeholder="Grade"
                            
                          />
                        </div>
  
                        <div class="form-group col-lg-6 col-md-12">
                          <label>Certificate</label>
                          <input class="form-control"
                            type="file"
                          
                            placeholder="Total program"
                          />
                        </div>
                       
                        <!-- Input -->

                        <div class="form-group col-lg-12 col-md-12 text-right">
                          <button @click.prevent="education_information_bachelor" class="theme-btn btn-style-one">
                            Save
                          </button>
                        </div>
                      
                    
                      
                    
                    
                    </div>
                    
                        <h5>Education Information (Masters)</h5>
                      <div class="row mt-4">

                        <!-- Input -->
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Instute Name</label>
                          <input
                            type="text"
                            v-model="education_masters.masters_instute_name"
                            placeholder="School Name"
                            required
                          />
                        </div>
  
                   
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Examination</label>
                          <input
                            type="text"
                            v-model="education_masters.masters_examination_name"
                            placeholder="Examination"
                            required
                          />
                        </div>

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Year</label>
                          <input
                            type="text"
                            v-model="education_masters.masters_year"
                            placeholder="Year"
                            required
                          />
                        </div>
  
  
                   
                       
                 
  
                      
                     
  
                      

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Duration</label>
                          <input
                            type="text"
                            v-model="education_masters.masters_duration"
                            placeholder="Duration"
                            
                          />
                        </div>
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Grade</label>
                          <input
                            type="text"
                            v-model="education_masters.masters_grade"
                            placeholder="Grade"
                            
                          />
                        </div>
  
                        <div class="form-group col-lg-6 col-md-12">
                          <label>Certificate</label>
                          <input class="form-control"
                            type="file"
                          
                            placeholder="Total program"
                          />
                        </div>
                       
                        <!-- Input -->
                        <div class="form-group col-lg-12 col-md-12 text-right">
                          <button @click.prevent="education_information_masters" class="theme-btn btn-style-one">
                            Save
                          </button>
                        </div>
                    
                    </div>

                  


                    <h5>Passport Information</h5>
                      <div class="row mt-4">

                        <!-- Input -->
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Passport Number</label>
                          <input
                            type="text"
                            v-model="passport_info.passport_number"
                            placeholder="Passport Number"
                            required
                          />
                        </div>
  
                   
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Country</label>
                          <input
                            type="text"
                            v-model="passport_info.country"
                            placeholder="Country"
                            required
                          />
                        </div>

                        <div class="form-group col-lg-4 col-md-12">
                          <label>Expiry Date</label>
                          <input
                            type="text"
                            v-model="passport_info.expiry_date"
                            placeholder="Expiry Date"
                            required
                          />
                        </div>
  
  
                   
                       
                 
  
                      
                     
  
                      

                     
                     
                        <div class="form-group col-lg-6 col-md-12">
                          <label>Passport</label>
                          <input class="form-control"
                            type="file"
                          
                            placeholder="Total program"
                          />
                        </div>
                       
                        <!-- Input -->
                        <div class="form-group col-lg-12 col-md-12 text-right">
                          <button @click.prevent="passport_information" class="theme-btn btn-style-one">
                            Save
                          </button>
                        </div>
                      
                    
                    
                    </div>
                    
                    


                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
    
    </div>
  </template>
  
  <script>
  import axios from "axios";
  // import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import vcookie from "vue-cookie";
  export default {
  
    data() {
      return {

        user_id : "",

        basic_info : {},

        education_school : {},

        education_higher : {},
         

        education_bachelor : {},

        education_masters : {},
        passport_info: {},



        
       
      };
    },
  
    methods: {

    add_basic_info(){
        var basic_info = {

          "first_name" : this.basic_info.first_name,
          "last_name" : this.basic_info.last_name,
          "parent_name" : this.basic_info.parent_name,
          "type" : this.basic_info.type,
          "nationality" : this.basic_info.nationality,
          "gender" : this.basic_info.gender,
          "dob" : this.basic_info.dob
          



        }
        
        axios.post('api/web/student/'+this.user_id,

         {
            "basic_information": JSON.stringify(basic_info),

            "_method" : "PUT"

         }


       ).then(res=>{

           if(res){
            this.$emit("get_message", "Student profile updated Successfully");


           }

       }).catch(error=>{
        console.log(error)


       })
    },

    get_user(){
      axios.get('api/web/student/'+this.user_id,)
      .then(res=>{


  
        var user = res.data.data

        this.basic_info =JSON.parse(user.basic_information)

        this.education_school =JSON.parse(user.education_information_school)

        this.education_higher =JSON.parse(user.education_information_higher_school)
        this.education_bachelor =JSON.parse(user.education_information_bachelor)
        this.education_masters =JSON.parse(user.education_information_masters)
        this.passport_info =JSON.parse(user.passport_information)

 
        
      })




    },

 



    add_eduction_school(){
        var education_school = {

          "school_name" : this.education_school.school_name,
          "examination_name" : this.education_school.examination_name,
          "year" : this.education_school.year,
          "duration" : this.education_school.duration,
          "grade" : this.education_school.grade,
          



        }
      
        axios.post('api/web/student/'+this.user_id,

         {
            "education_information_school": JSON.stringify(education_school),

            "_method" : "PUT"

         }


       ).then(res=>{

           if(res){
            this.$emit("get_message", "Student profile updated Successfully");


           }

       }).catch(error=>{
        console.log(error)


       })
    },

    
    


    education_information_higher_school(){
        var education__higher_school = {

          "higher_school_name" : this.education_higher.higher_school_name,
          "higher_examination_name" : this.education_higher.higher_examination_name,
          "higher_year" : this.education_higher.higher_year,
          "higher_duration" : this.education_higher.higher_duration,
          "higher_grade" : this.education_higher.higher_grade,
          



        }
      
        axios.post('api/web/student/'+this.user_id,

         {
            "education_information_higher_school": JSON.stringify(education__higher_school),

            "_method" : "PUT"

         }


       ).then(res=>{

           if(res){
            this.$emit("get_message", "Student profile updated Successfully");


           }

       }).catch(error=>{
        console.log(error)


       })
    },


    education_information_bachelor(){
        var education__bachelor = {

          "bachelor_instute_name" : this.education_bachelor.bachelor_instute_name,
          "bachelor_eximation_name" : this.education_bachelor.bachelor_eximation_name,
          "bachelor_year" : this.education_bachelor.bachelor_year,
          "bachelor_duration" : this.education_bachelor.bachelor_duration,
          "bachelor_grade" : this.education_bachelor.bachelor_grade,
          



        }
      
        axios.post('api/web/student/'+this.user_id,

         {
            "education_information_bachelor": JSON.stringify(education__bachelor),

            "_method" : "PUT"

         }


       ).then(res=>{

           if(res){
            this.$emit("get_message", "Student profile updated Successfully");


           }

       }).catch(error=>{
        console.log(error)


       })
    },


    
    education_information_masters(){
        var education__masters = {

          "masters_instute_name" : this.education_masters.masters_instute_name,
          "masters_examination_name" : this.education_masters.masters_examination_name,
          "masters_year" : this.education_masters.masters_year,
          "masters_duration" : this.education_masters.masters_duration,
          "masters_grade" : this.education_masters.masters_grade,
          



        }
      
        axios.post('api/web/student/'+this.user_id,

         {
            "education_information_masters": JSON.stringify(education__masters),

            "_method" : "PUT"

         }


       ).then(res=>{

           if(res){
            this.$emit("get_message", "Student profile updated Successfully");


           }

       }).catch(error=>{
        console.log(error)


       })
    },


    passport_information(){
        var passport = {

          "passport_number" : this.passport_info.passport_number,
          "country" : this.passport_info.country,
          "expiry_date" : this.passport_info.expiry_date,
    



        }
      
       axios.post('api/web/student/'+this.user_id,

         {
            "passport_information": JSON.stringify(passport),

            "_method" : "PUT"

         }


       ).then(res=>{

           if(res){
            this.$emit("get_message", "Student profile updated Successfully");


           }

       }).catch(error=>{
        console.log(error)


       })
    },

    showprofile() {
      const user = JSON.parse(vcookie.get("user"));
      this.user_id = user.id


      if (user) {
        this.name = user.name;

     
        this.logo='images/user.png'
          
      
  
      
      } 

      
    },






     

  
    
    },
    mounted: function () {  

      this.showprofile()
  this.get_user()


      },
  };
  </script>
  
  
  
  <style>
  .button_plus {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    cursor: pointer;
    border: 2px solid #095776;
    display: flex;
  
    /* Mittig */
    top: 44%;
    left: 90%;
  }
  
  .button_plus:after {
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    height: 4px;
    width: 50%;
    background: #095776;
    top: 50%;
    left: 50%;
  }
  
  .button_plus:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #095776;
    height: 50%;
    width: 4px;
  }
  
  .button_plus:hover:before,
  .button_plus:hover:after {
    background: #fff;
    transition: 0.2s;
  }
  
  .button_plus:hover {
    background-color: #095776;
    transition: 0.2s;
  }
  </style>